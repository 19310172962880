@use 'sass:color';
@use 'fns';

@mixin enabled {
  // since we're working with links sometimes and buttons other times, either
  // :disabled or .disabled may be applicable
  &:not(.disabled):not(:disabled) {
    @content;
  }
}

@mixin btn-base($bg-color: $cs-primary-0) {
  $color-base: $bg-color;
  $color-dark: color.adjust($bg-color, $lightness: -10%);
  $text-color: fns.text-contrast($bg-color);

  align-items: center;
  background: $bg-color;
  color: $text-color;
  display: inline-flex;
  justify-content: center;
  padding: 2px $cs-pad;
  text-decoration: none;
  transition: 0.125s background;

  @include enabled {
    &:active,
    &:hover {
      background: $color-dark;
      color: $text-color;
      cursor: pointer;
      border: {
        color: $color-dark;
      }
    }
  }

  font: {
    size: $cs-font-size;
    weight: $cs-weight-1;
  }

  border: {
    color: transparent;
    radius: 4px;
    style: solid;
    width: 1px;
  }

  min: {
    height: $cs-input;
    width: 180px;
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;
    opacity: 0.625;
  }

  + .btn,
  + button {
    margin-left: $cs-pad;
  }

  &.btn-lg {
    font-size: $cs-font-size-lg;
    height: $cs-font-size-xl * 2.5;
    padding: $cs-pad;
  }
}

@mixin btn-outline($color-base: $cs-primary-0) {
  /*
   * We're going to operate under the assumption that this is going over a white
   * background. If the contrast between white and the regular color is
   * insufficient, darken the color slightly and use that instead.
   */
  $color: fns.text-contrast(
    #fff,
    $color-base,
    color.adjust($color-base, $lightness: -17.5%)
  );

  background: transparent;
  border: 1px solid $color;
  color: $color;

  @include enabled {
    &:active,
    &:hover {
      background: $color-base;
      border-color: $color-base;
      color: $cs-color-white;
    }
  }
}

@mixin btn-icon($color-base: $cs-primary-0) {
  background: transparent;
  border-width: 0;
  font-size: $cs-font-size-xl;
  height: $cs-pad-xl;
  min-height: unset;
  min-width: unset;
  padding: 0;
  width: $cs-pad-xl;

  @include enabled {
    &:hover {
      background-color: transparent;
      opacity: 0.75;
    }
  }

  i {
    color: $color-base;
  }

  &:disabled i {
    opacity: 0.625;
  }
}

@mixin btn($color, $text-color: $cs-color-white) {
  @include btn-base($color);
  &.btn-outline {
    @include btn-outline($color);
  }
}
