@use 'sass:color';

@import '../partials/variables';

$width: 75vw;

.content-overlay {
  &,
  .slide,
  .slide + .bg {
    position: fixed;
    right: 0;
    top: 0;
  }
}

.content-overlay {
  // close icon
  button[title='Close'] {
    left: $cs-pad;
    position: absolute;
    top: $cs-pad;
  }

  .slide {
    background: $cs-color-white;
    border: 1px solid $cs-grey-3;
    height: 100vh;
    overflow-y: auto;
    padding: $cs-pad-xl * 2;
    transform: translateX($width);
    transition: transform $cs-animation-duration;
    width: $width;
    z-index: 0;

    // dark background overlay
    + .bg {
      background: color.change($cs-grey-0, $alpha: 0);
      height: 100vh;
      transition: background $cs-animation-duration;
      width: 100vw;
      z-index: -1;
    }

    &.in {
      transform: translateX(0);
      + .bg {
        background: color.change($cs-grey-0, $alpha: 0.875);
      }
    }
  }
}
