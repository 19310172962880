.batch-info {
  h2,
  .h2 {
    font-size: $cs-font-size-xl;
    font-weight: $cs-weight-1;
    margin: 0 3px;
  }
  // coercing the editiable input field to match its uneditable counterpart
  .h2 {
    height: 1em;
    margin: 0;
    margin-bottom: 8px;
  }

  p {
    color: $cs-grey-3;
    margin-left: 3px;
    margin-top: $cs-pad-xs;
  }

  #entitlement_batch_to_crm_id,
  #purchasing_account {
    font-size: $cs-font-size-lg;
    font-style: italic;
  }

  #entitlement_batch_to_crm_id span,
  #purchasing_account,
  h2 {
    cursor: default;
  }

  dl {
    grid-template-columns: 2fr 10fr;
    margin: $cs-pad-xs 3px;
  }
}
