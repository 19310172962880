@use 'sass:color';
@use 'sass:map';

@import './variables';

.table {
  border-collapse: collapse;
  width: 100%;

  tr.invalid td {
    background-color: color.change(
      map.get($cs-color-themes, 'error'),
      $alpha: 0.15
    );
  }

  td,
  th {
    border-bottom: 1px solid $cs-grey-6;
    padding: 10px 1px;
    text-align: left;
    vertical-align: middle;
  }

  th {
    font-size: $cs-font-size-lg;
    font-weight: $cs-weight-1;
  }

  &.table-sm {
    th,
    td {
      padding: $cs-pad-xs 0;
    }
  }

  th.checkbox,
  td.checkbox {
    padding: 0 $cs-pad-xs 0 0;
    text-align: center;
    vertical-align: middle;
  }
}

.table-select {
  tbody tr:not(.active):hover {
    background: color.mix($cs-color-white, $cs-grey-6, 75%);
    cursor: pointer;
  }
}

.table input[type='checkbox'] {
  min-height: initial;
}

tr.active {
  background-color: color.change($cs-primary-3, $alpha: 0.25);
}

// shrink some of the inputs to be more table-appropriate
form .table {
  input,
  select {
    height: $cs-font-size * 2;
    padding: 0 $cs-pad-xs;
  }
}

.sortable {
  cursor: pointer;
  &:not(.disabled):hover {
    color: $cs-primary-0;
  }
  div {
    width: fit-content;
    i,
    span {
      pointer-events: none;
    }
  }
  i {
    color: $cs-primary-3;
    margin-left: 3px;
    position: relative;
    top: 2px;
  }
  &.active-column {
    color: $cs-primary-0;
  }
}
