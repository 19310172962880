@import '../partials/variables';

/**
 * This is operating under the assumption that the breadcrumb navigation will
 * eventually an <ol />. If so, the ::marker pseudo-element will need to be
 * bolded with the rest of the selected nav item.
 */

.breadcrumbs {
  display: flex;
  flex-direction: row;
  margin: $cs-pad-lg $cs-pad-xl $cs-pad-lg $cs-pad-lg;
  padding: 0;

  a,
  li {
    font-size: $cs-font-size-lg;
    margin: 0;
    padding: 0;
    text-decoration: none;
  }

  a:hover {
    color: $cs-grey-3;
  }

  li.active,
  li.active::marker {
    font-weight: $cs-weight-1;
  }

  // add a > symbol after each breadcrumb but the last
  li:not(:last-child)::after {
    color: $cs-grey-2;
    content: '>';
    display: inline-block;
    font-weight: normal;
    margin: 0 $cs-pad-xl 0 $cs-pad;
    text-decoration: none;
  }

  li.breadcrumb.disabled {
    color: $cs-grey-3;
    &::after {
      color: $cs-grey-3;
    }
  }
}
