@use 'sass:color';
@import './partials/variables';

#manual-toc {
  position: fixed;
  width: 200px;

  ul {
    list-style-type: none;
    margin: 0;
  }
  ul:not(.root) {
    padding: $cs-pad-lg 0;
  }
  ul.root {
    padding-left: 0;
    padding-top: 0;
  }

  a.active {
    color: $cs-primary-2;
    text-decoration: underline;
  }
  h5 {
    font-size: $cs-font-size-md;
    margin: 0;
  }
  li {
    padding-bottom: $cs-pad-sm * 0.5;
  }
}

.manual {
  padding-left: 200px;

  img.scrn {
    border: 1px solid $cs-primary-2;
    border-radius: 4px;
    margin-bottom: $cs-pad-sm;
    margin-top: $cs-pad-sm;
    width: 850px;
  }

  img:not(.scrn) {
    margin-right: $cs-pad-sm;
    vertical-align: middle;
    width: 175px;
  }

  li {
    padding-bottom: $cs-pad-sm;
  }
  ul {
    margin-top: $cs-pad-sm;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    padding-bottom: $cs-pad-sm;
    padding-top: $cs-pad-sm;
  }
  img.status {
    margin: 0;
    width: auto;
  }

  .ico {
    img {
      width: auto;
    }
    li {
      list-style: none;
    }
  }
}
