@use 'sass:color';

/**
 * Colors for text/backgrounds.
 */

// Capstone Purple
$cs-primary-0: #66008c;
$cs-primary-1: #8c40a9;
$cs-primary-2: #b380c6;
$cs-primary-3: #d9bfe2;

// Capstone Red
$cs-secondary-0: #c72830;
$cs-secondary-1: #d55e64;
$cs-secondary-2: #e39398;
$cs-secondary-3: #f1c9cb;

// tertiary colors
$cs-magenta: #f36;
$cs-orange: #dc8c29;
$cs-yellow: #ede708;
$cs-green: #8fd145;
$cs-cyan: #4ec0d3;
$cs-blue: #3d5197;

// tertiary tints
$cs-magenta-alt: #ff668c;
$cs-orange-alt: #e5a95f;
$cs-yellow-alt: #f2ed46;
$cs-green-alt: #abdd74;
$cs-cyan-alt: #7ad0de;
$cs-blue-alt: #6e7db1;

// greys
$cs-grey-0: #0d1011;
$cs-grey-1: #494c4c;
$cs-grey-2: #545c5c;
$cs-grey-3: #7f8585;
$cs-grey-4: #b2b1b0;
$cs-grey-5: #c5c4c4;
$cs-grey-6: #ececeb;

// aliases
$cs-color-white: #fff;
$cs-color-body: $cs-grey-2;

// theme colors; in a map for iteration
$cs-color-themes: (
  'light': $cs-primary-2,
  'dark': $cs-primary-0,
  'info': $cs-cyan-alt,
  'success': $cs-green-alt,
  'warning': $cs-orange-alt,
  'error': $cs-secondary-0,
  'muted': $cs-grey-2
);

/**
 * Sizes for spacing—margins, padding, etc.
 */
$cs-pad: 15px;
$cs-pad-xl: $cs-pad * 2; // 32px
$cs-pad-lg: $cs-pad * 1.5; // 24px
$cs-pad-sm: $cs-pad * 0.75; // 12px
$cs-pad-xs: $cs-pad * 0.5; // 8px

// Font sizes
$cs-font-size: 16px;
$cs-font-size-sm: $cs-font-size * 0.75; // 12px
$cs-font-size-md: $cs-font-size * 1.125; // 18px
$cs-font-size-lg: $cs-font-size * 1.25; // 20px
$cs-font-size-xl: $cs-font-size * 1.5; // 24px

// Font 'styles'
$cs-weight-0: 400;
$cs-weight-1: 600;

// Header sizes
$cs-font-size-h1: $cs-font-size * 4.5; // 72px
$cs-font-size-h2: $cs-font-size * 2.5; // 40px
$cs-font-size-h3: $cs-font-size * 2; // 32px
$cs-font-size-h4: $cs-font-size * 1.5; // 24px
$cs-font-size-h5: $cs-font-size * 1.25; // 20px
$cs-font-size-h6: $cs-font-size; // 16px

// Input sizes
$cs-input: $cs-font-size * 2.5; // 40px

$cs-font-family: Goldplay, -apple-system, BlinkMacSystemFont, 'Segoe UI',
  'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
  'Helvetica Neue', sans-serif;

$cs-animation-duration: 200ms;
