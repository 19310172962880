@import '../partials/variables';

.page-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: $cs-pad-xs;
  width: 100%;
}

.page-content {
  $window-pad-sm: $cs-pad-lg * 2;
  $window-pad-lg: $cs-pad-xl * 2;
  width: 100%;
  padding: {
    bottom: $window-pad-sm;
    left: $window-pad-lg * 2;
    right: $window-pad-sm;
    top: $window-pad-sm;
  }
}

.has-overlay {
  overflow: {
    x: hidden;
    y: hidden;
  }
}
