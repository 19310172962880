@import '../partials/variables';

$progress-width: 25%;
$progress-duration: 2s;
$fade-duration: 200ms;

.progress-container .fade {
  opacity: 0;
  transition: opacity $fade-duration;

  &.in {
    opacity: 0.5;
  }

  &.out {
    opacity: 0;
  }
}

.progress-indicator,
.progress-indicator::after {
  display: block;
  height: $cs-pad-xs;
  left: 0;
  position: fixed;
  top: 0;
}

.progress-indicator {
  background-color: $cs-primary-2;
  width: 100%;

  &::after {
    animation: indeterminate $progress-duration infinite linear;
    background: $cs-primary-1;
    content: '';
    width: $progress-width;
  }

  &::-webkit-progress-bar {
    background-color: transparent;
  }
}

@keyframes indeterminate {
  0% {
    margin-left: -$progress-width;
    margin-right: 100%;
  }
  100% {
    margin-left: 100%;
    margin-right: $progress-width;
  }
}
