@use 'sass:color';

@use 'fns';

@mixin msg-base($bg-color: $cs-primary-0) {
  $text-color: fns.text-contrast($bg-color);

  background: $bg-color;
  color: $text-color;
  padding: $cs-pad;

  border: {
    radius: 4px;
    style: none;
    width: 1px;
  }

  ol.message {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ol.message-list {
    list-style: numeric inside;
    margin: $cs-pad-xs 0;
    padding: {
      bottom: 0;
      left: $cs-pad;
    }

    + a {
      margin-top: $cs-pad-xs;
    }

    li {
      line-height: 1.5;
    }
  }

  li,
  h1,
  h2,
  h3,
  h4,
  .message-content,
  .message-list {
    color: $text-color;
  }
}

@mixin msg-outline($base) {
  /*
   * We're going to operate under the assumption that this is going over a white
   * background. If the contrast between white and the regular color is
   * insufficient, darken the color slightly and use that instead.
   */
  $color: fns.text-contrast(
    #fff,
    $base,
    color.adjust($base, $lightness: -17.5%)
  );

  background: #0000;
  border: {
    color: $color;
    style: solid;
  }

  li,
  h1,
  h2,
  h3,
  h4,
  .message-content,
  .message-list {
    color: $color;
    + a {
      color: color.adjust($color, $lightness: 10%);
    }
  }
}

// @todo: make success color contrast friendlier
@mixin msg($color) {
  @include msg-base($color);
  &.msg-outline {
    @include msg-outline($color);
  }
}
