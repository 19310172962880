@import '../partials/variables';

dl {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: auto;

  dd,
  dt {
    color: $cs-grey-2;
    font-family: $cs-font-family;
    font-size: $cs-font-size;
    margin-bottom: $cs-pad-sm;
  }

  dt {
    grid-column: 1;
    text-transform: uppercase;
  }

  dd {
    grid-column: 2;
    text-align: left;
  }
}
