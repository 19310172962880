@import './partials/variables';
@import './partials/utils/btn';

table.authenticators {
  td ul {
    margin: 0;
    padding: 0;
  }

  ul li {
    line-height: 1.3;
    list-style: none;
  }
}

.user-details {
  max-width: 540px;

  .flex {
    align-items: flex-end;

    .form-group {
      flex: 0 0 540px;
    }

    .generate-password {
      flex: 1 0 auto;
    }
  }
}

.user-credentials {
  padding: 0;

  button.btn {
    border-radius: 0;
    margin-left: 0;

    &:not(:last-child) {
      border-right-width: 0;
    }
  }
}

.user-roles,
.reset-sqs-form {
  margin-top: $cs-pad;
  &.text-muted {
    label {
      color: unset !important;
    }
  }
  label {
    margin-bottom: $cs-pad-xs;
  }

  .form-group.checkbox {
    label {
      height: $cs-font-size-xl;
      margin: 5px 0;
    }
    input.multi-site {
      margin-left: $cs-pad-xl;
    }
  }
}
.reset-sqs-dialog {
  padding: $cs-pad-xl;

  td:first-child {
    width: 150px;
  }
  tr {
    height: $cs-pad-xl * 1.5;
    &:nth-child(2)span {
      outline: 2px solid $cs-primary-3;
      padding: $cs-pad-xs;
    }
  }
}

#input-password,
#input-token {
  flex-grow: 1;
  + .btn-icon.btn-input {
    height: $cs-input;
    left: -$cs-pad-lg;
    position: relative;
    width: 0;
  }
}

#finish_auth {
  .status {
    width: fit-content;
  }
  .account-details {
    th {
      padding-right: $cs-pad-xl;
    }
  }
}
