@charset "UTF-8";
/*
 * @todo: update imports with SCSS module @use directives
 */

@import '~@fortawesome/fontawesome-free/css/all.min.css';

// variables, mixins
@import './scss/partials/fonts';
@import './scss/partials/variables';
@import './scss/partials/utils/btn';
@import './scss/partials/utils/msg';

// core styles
@import './scss/partials/base';
@import './scss/partials/utilities';
@import './scss/partials/tables';
@import './scss/partials/forms';

// UI components
@import './scss/components/breadcrumbs';
@import './scss/components/definition-list';
@import './scss/components/content';
@import './scss/components/dialog';
@import './scss/components/menu';
@import './scss/components/overlay';
@import './scss/components/paginator';
@import './scss/components/progress';
@import './scss/components/spinner';
@import './scss/components/tabs';
@import './scss/components/copy-clipboard';

// top-level routes
@import './scss/accounts';
@import './scss/authenticators';
@import './scss/batches';
@import './scss/entitlements';
@import './scss/connections';
@import './scss/login';
@import './scss/products';
@import './scss/manual';

* {
  box-sizing: border-box;
}

body {
  color: $cs-color-body;
  font-size: $cs-font-size;
  margin: 0;
  .app {
    margin: auto;
    width: 100vw;
  }
}

html,
body,
input,
button,
select {
  // there may be other tags with user agent styling that I'm forgetting.
  font-family: $cs-font-family;
}
