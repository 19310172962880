@use 'sass:map';

/**
 * Margin helpers, a la Bootstrap.
 */

.mx {
  margin-left: $cs-pad;
  margin-right: $cs-pad;
}

.my {
  margin-bottom: $cs-pad;
  margin-top: $cs-pad;
}

.mb {
  margin-bottom: $cs-pad;
}

.mt {
  margin-top: $cs-pad;
}

.ml {
  margin-left: $cs-pad;
}

.mr {
  margin-right: $cs-pad;
}

.m {
  margin: $cs-pad;
}

/**
 * Padding helpers, a la Bootstrap.
 */

.px {
  padding-left: $cs-pad;
  padding-right: $cs-pad;
}

.py {
  padding-bottom: $cs-pad;
  padding-top: $cs-pad;
}

.pb {
  padding-bottom: $cs-pad;
}

.pt {
  padding-top: $cs-pad;
}

.pl {
  padding-left: $cs-pad;
}

.pr {
  padding-right: $cs-pad;
}

.p {
  padding: $cs-pad;
}

// borders

$border: 1px solid $cs-grey-4;

.by {
  border-bottom: $border;
  border-top: $border;
}

.bt {
  border-top: $border;
}

.bb {
  border-bottom: $border;
}

.bx {
  border-left: $border;
  border-right: $border;
}

.bl {
  border-left: $border;
}

.br {
  border-right: $border;
}

// flexbox helpers

.flex {
  display: flex;
}

.column {
  flex-direction: column;
}

.flex-end {
  justify-content: flex-end;
}

// page component helpers

.actions {
  margin-top: $cs-pad-xl;
}

// text utilities

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@each $theme in ('light', 'dark', 'info', 'success', 'error') {
  .text-#{$theme} {
    color: map.get($cs-color-themes, $theme);
  }
}

.text-primary {
  color: $cs-primary-0;
}

.text-muted {
  color: $cs-grey-3;
}

.text-italic {
  font-style: italic;
}

.text-small {
  font-size: $cs-font-size-sm;
}

.text-uppercase {
  text-transform: uppercase;
}
