@use 'sass:math';

@import '../partials/variables';

$spinner-duration: 3s;
$spinner-inner-width: $cs-pad-sm;
$spinner-outer-width: $cs-pad;

$spinner-outer: $cs-pad-xl * 4;
$spinner-inner: $spinner-outer + $spinner-outer-width - $spinner-inner-width;

.spinner-container {
  display: flex;
  justify-content: center;
  padding: $cs-pad-xl;
}

.spinner {
  align-items: center;
  animation: spin $spinner-duration linear infinite;
  border: $spinner-outer-width solid $cs-primary-0;
  border-left-color: transparent;
  display: flex;
  height: $spinner-outer;
  justify-content: center;
  width: $spinner-outer;

  &,
  > progress {
    border-radius: 50%;
    box-sizing: content-box;
    margin: -$spinner-outer-width;
  }

  > progress {
    background-color: transparent;
    border: $spinner-inner-width solid transparent;
    border-left-color: $cs-primary-3;
    height: $spinner-inner;
    width: $spinner-inner;

    &::-webkit-progress-bar {
      background-color: transparent;
    }

    &::-moz-progress-bar {
      background-color: transparent;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
