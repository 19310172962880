@use 'sass:color';

@import '../partials/variables';
@import '../partials/utils/btn';

nav.tabs {
  border-bottom: 1px solid $cs-grey-4;
  display: flex;
  // bottom border is flush on the left/right, so we add a negative margin
  margin: $cs-pad-lg (-$cs-pad-xl);
  // ...and add padding to offset the negative margin
  padding: 0 $cs-pad-xl;
}

.tabs .tab {
  align-items: center;
  background: $cs-grey-6;
  color: $cs-grey-2;
  display: inline-flex;
  justify-content: center;
  margin-right: $cs-pad-sm;
  padding-top: 4px;
  transition: 0.125s background;

  font: {
    size: $cs-font-size;
    weight: $cs-weight-1;
  }

  text: {
    decoration: none;
    transform: uppercase;
  }

  border: {
    color: transparent;
    radius: 8px 8px 0 0;
    style: solid;
    width: 1px;
  }

  min: {
    height: $cs-input;
    width: 180px;
  }

  &:hover {
    background: $cs-grey-5;
    color: $cs-grey-2;
    cursor: pointer;
  }

  &.active,
  &.active:hover {
    background-color: $cs-color-white;
    cursor: default;
    // to hide the tab-bar's border, we offset the bottom margin by -1
    margin-bottom: -2px;
    padding-top: 0;

    border: {
      color: $cs-grey-4 $cs-grey-4 $cs-color-white;
      width: 1px;
    }
  }
}
