@import './partials/variables';

table.entitlements {
  text-align: left;

  &.batch {
    th.checkbox,
    td.checkbox {
      flex: 0 0 64px;
    }
  }

  .checkbox {
    align-items: center;
    height: $cs-input;
    padding: unset;
    width: 40px;

    input {
      width: fit-content;
    }
  }
}

.filters.entitlements form {
  margin-bottom: $cs-pad;

  .form-group {
    flex: 1 0 auto;

    &:nth-child(1),
    &:nth-child(2) {
      flex: 0 0 120px;
    }

    &:nth-child(3) {
      flex: 3 0 auto;
    }
  }
}

#content_search_form table {
  th,
  td {
    height: fit-content;
    padding: $cs-pad-xs;
    text-align: left;
  }

  th:first-of-type,
  td:first-of-type {
    width: 30px;
  }
}

.bulk-edit-form {
  width: 75%;
  #start_date,
  #end_date {
    min-width: 250px;
  }
  #start_date::placeholder,
  #end_date::placeholder {
    color: $cs-grey-2;
    opacity: 1;
  }

  .form-actions {
    margin-top: 0;
    padding-top: 0;
  }

  input[type='date']::before {
    color: $cs-grey-2;
    content: attr(label);
    padding-right: 1ch;
  }
}
.select-all-items {
  margin-bottom: $cs-pad-xs;
  margin-top: $cs-pad-lg;
  a,
  span.bold {
    color: $cs-primary-0;
    font-weight: $cs-weight-1;
  }
}

.entitlement-collections {
  max-width: 320px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
