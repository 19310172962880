.paginator {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .next .btn,
  .prev .btn {
    margin: 0;
  }

  select {
    margin: 0 $cs-pad;
    padding: 0 $cs-pad-xs;
  }
}
