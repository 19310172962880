@use 'sass:color';

@import './partials/variables';

.account-info {
  h2 {
    font-size: $cs-font-size-xl;
    font-weight: $cs-weight-1;
    margin: 0;
  }

  p {
    color: $cs-grey-3;
    margin-top: $cs-pad-xs;
  }

  #account_crm_id {
    font-size: $cs-font-size-lg;
    font-style: italic;
  }
}
.archived-column {
  text-align: center !important;
}

.account-details {
  &.address-data {
    margin-top: $cs-pad-xl * 3;
  }
  table {
    margin-bottom: $cs-pad-xl;
    text-align: left;

    td,
    th {
      border: 0;
    }

    th {
      color: $cs-grey-3;
      text-transform: uppercase;
      font: {
        size: $cs-font-size;
        weight: $cs-weight-0;
      }
    }
  }
}
.crm-form-group {
  display: flex;
  &.bold {
    > * {
      font-weight: $cs-weight-1;
    }
  }
  input {
    width: 75%;
  }
  .help-text {
    &.hidden {
      display: none;
    }
  }
}

.merge-details-list {
  list-style: none;
  margin-bottom: $cs-pad-xl;
  padding-left: $cs-pad-xs;
  li {
    margin: $cs-pad;
    i {
      color: $cs-green;
      font-size: $cs-font-size-lg;
      margin-right: $cs-pad-xs;
    }
  }
}

.merge-status {
  margin-top: $cs-pad;
  width: 660px;
}

.merge-accounts {
  .title {
    margin-bottom: 0;
    padding-bottom: $cs-pad-xl;
    padding-top: $cs-font-size-xl;
  }
  p span {
    font-weight: $cs-weight-1;
  }
  .card-group {
    display: flex;
    :not(.account-info):last-child {
      margin-left: $cs-pad-xl;
    }
  }
  .account-details {
    margin-bottom: $cs-pad-xl;
    .primary {
      margin-left: $cs-pad-lg;
    }
  }
  .default-cursor {
    cursor: default;
  }

  .message {
    font-size: $cs-font-size-md;
    height: $cs-pad-xl * 1.2;
    margin: $cs-pad-xl 0 ($cs-pad-xl) 0;
    span {
      font-weight: $cs-weight-1;
    }
  }
  .card {
    border: 2px solid $cs-primary-0;
    border-radius: 4px;
    float: left;
    min-width: 300px;
    padding: $cs-pad-xl 0 0 $cs-pad-xl;
    width: 20%;
    &.active {
      background-color: color.change($cs-primary-3, $lightness: 90%);
    }
  }
  .section-header {
    color: $cs-primary-0;
    font-size: $cs-font-size-xl;
    font-weight: $cs-weight-1;
  }
  .account-info {
    display: flex;
    flex-direction: column;
    min-height: 120px;

    span {
      display: flex;
      justify-content: flex-end;
      padding-bottom: $cs-pad;
      padding-right: $cs-pad;
    }
    :nth-child(1) {
      color: $cs-grey-1;
      font-size: $cs-font-size-xl;
      font-weight: $cs-weight-1;
      margin: 0;
    }
    :nth-child(2) {
      color: $cs-grey-1;
      flex-grow: 1;
      margin: 0;
    }
  }
}
.account .filters {
  &.connections select {
    width: 240px;
  }

  &.batches form {
    align-items: flex-end;
  }
}

.account.disabled {
  .account-status {
    display: inline-block;
    font-style: italic;
    font-weight: 300;
    margin-left: 1ch;
    text-transform: uppercase;
  }
}

.select-all {
  border-bottom: 1px solid $cs-grey-6;
  border-top: 1px solid $cs-grey-6;
  padding: $cs-pad-sm 1px;
}

.connections-form-group {
  display: flex;
  flex: 1 0 auto;
  .my {
    flex: 1 0 auto;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  button {
    align-self: flex-start;
    margin-left: $cs-pad;
    margin-top: $cs-pad-xl;
  }
  .checkbox label {
    font-weight: unset;
  }
}
