$base: '../../../public/assets';

@font-face {
  src: url('#{$base}/Goldplay-Bold.woff2') format('woff2'),
    url('#{$base}/Goldplay-Bold.woff') format('woff'),
    url('#{$base}/Goldplay-Bold.woff') format('eot');

  font: {
    family: Goldplay;
    weight: 800;
  }
}

@font-face {
  src: url('#{$base}/Goldplay-SemiBold.woff2') format('woff2'),
    url('#{$base}/Goldplay-SemiBold.woff') format('woff'),
    url('#{$base}/Goldplay-SemiBold.woff') format('eot');

  font: {
    family: Goldplay;
    weight: 600;
  }
}

@font-face {
  src: url('#{$base}/Goldplay-Medium.woff2') format('woff2'),
    url('#{$base}/Goldplay-Medium.woff') format('woff'),
    url('#{$base}/Goldplay-Medium.woff') format('eot');

  font: {
    family: Goldplay;
    weight: 400;
  }
}

@font-face {
  src: url('#{$base}/Goldplay-MediumIt.woff2') format('woff2'),
    url('#{$base}/Goldplay-MediumIt.woff') format('woff'),
    url('#{$base}/Goldplay-MediumIt.woff') format('eot');

  font: {
    family: Goldplay;
    style: italic;
    weight: 400;
  }
}
