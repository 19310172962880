@use 'sass:color';
@use 'sass:map';

@import './variables';

.form-group {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  label {
    margin-bottom: $cs-pad-xs;
  }

  // if we have a button right after a form group, bring it inline with the input instead of the label
  + button {
    align-self: flex-end;
    height: $cs-input;
  }
}

.form-actions {
  display: flex;
  height: $cs-input;
  margin-top: $cs-pad;
  padding-top: $cs-pad-sm;
}

.form-error {
  color: map.get($cs-color-themes, 'error');
  margin-bottom: $cs-pad;
  padding: $cs-pad;
  border: {
    color: map.get($cs-color-themes, 'error');
    style: solid;
    width: 1px;
  }
}

.form,
form {
  // @todo: clean this up
  &.flex,
  .flex {
    &:not(.column) .form-group {
      margin-bottom: 0;
      margin-right: $cs-pad;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  input,
  select,
  textarea {
    border: 1px solid $cs-grey-4;
    border-radius: 4px;
    color: $cs-color-body;
    font-size: $cs-font-size;
    min-height: $cs-input;
    padding: $cs-pad-xs;

    &:disabled {
      color: map.get($cs-color-themes, 'muted');
    }
  }

  input:focus {
    border-color: $cs-grey-5;
    outline: none;
  }

  label {
    color: $cs-grey-2;
    font: {
      size: $cs-font-size;
      weight: $cs-weight-1;
    }
  }
}

.form-group.checkbox {
  align-self: flex-end;

  label {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    height: $cs-input;
    justify-content: flex-end;
    margin: 0;
    width: fit-content;
    input {
      margin: 0 $cs-pad-xs 0 0;
    }
  }
}

input[type='date'] {
  font-family: $cs-font-family;
}
