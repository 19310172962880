.table.edit-connections {
  td,
  th {
    vertical-align: middle;
  }
}

.edit-connections {
  tr.disabled {
    opacity: 0.5;
  }
}
