@import './variables';
@import './utils/btn';
@import './utils/msg';

/**
 * Inline/block-level content
 */

i {
  color: $cs-grey-3;
}

a {
  color: $cs-primary-0;
  font-weight: $cs-weight-0;
  text-decoration: none;
  // tag takes precedence over class, so the link buttons in the top right
  // were being funky
  &:not(.btn):hover {
    color: $cs-primary-1;
  }
}

p {
  margin: $cs-pad-xs 0 0;
  padding: 0;
}

/**
 * Headings
 */
h1,
h2,
h3,
h4 {
  font-weight: $cs-weight-1;
  line-height: 1;
  margin: 0;
  padding: 0;
}

h1,
.h1 {
  color: $cs-grey-2;
  font-size: $cs-font-size-h1;
  line-height: 1;
}

h2,
.h2 {
  font-size: $cs-font-size-h2;
}

h3,
.h3 {
  font-size: $cs-font-size-h3;
  margin-bottom: $cs-pad-xs;
}

h4,
.h4 {
  color: $cs-grey-3;
  font-size: $cs-font-size-h4;
  line-height: $cs-font-size-h3;
  margin-bottom: $cs-pad-xs;
  text-transform: uppercase;
}

/**
 * Color-themed components
 */

.btn {
  @include btn($cs-primary-1, #ffffffde);
}

.btn-icon {
  @include btn-icon;
}

.msg {
  @include msg($cs-primary-1);
}

@each $theme, $color in $cs-color-themes {
  .btn.btn-#{$theme} {
    @include btn($color);
  }

  .btn-icon.btn-#{$theme} {
    @include btn-icon($color);
  }

  .msg.msg-#{$theme} {
    @include msg($color);
  }

  .text-#{$theme} {
    color: $color;
  }
}

.btn.btn-lg.btn-outline {
  border-width: 2px;
}

input.editable {
  box-sizing: content-box;
  color: $cs-grey-1;
  display: block;
  outline: none;
  padding: 0 3px;
  width: 100%;

  border: {
    radius: 4px;
    style: none;
    width: 1px;
  }

  &:focus {
    color: $cs-grey-5;
    padding: 2px;
    border: {
      color: $cs-grey-5;
      style: solid;
    }
  }
}
.dialog-message {
  .bold {
    font-weight: $cs-weight-1;
    margin: $cs-pad 0 !important;
  }
}
