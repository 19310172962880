.copy-btn {
  background-color: transparent;
  border: 0;
  border-radius: 4px;
  margin-left: $cs-pad-xs;
  padding: 2px 8px;
  i {
    color: $cs-grey-1;
  }
  &:hover {
    background-color: $cs-primary-3;
  }
}
