@use 'sass:color';
@import './partials/variables';

#login {
  padding-top: $cs-pad-xl * 5;

  // addresses background misbehavior when the viewport changes.
  &::after {
    background: color.change($cs-primary-3, $alpha: 0.5);
    bottom: 0;
    content: ' ';
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: -1;
  }

  h1 {
    color: $cs-grey-3;
    font-size: $cs-font-size-h1;
    text-align: center;
  }

  .btn {
    border-radius: 8px;
    height: $cs-font-size-xl * 1.5;
    line-height: 0.5;
    width: 160px;
  }

  label {
    line-height: 2;
    font: {
      size: $cs-font-size-lg;
      weight: $cs-weight-0;
    }
  }

  input {
    height: $cs-font-size * 3;
    + a {
      font-size: $cs-font-size;
      margin-top: $cs-pad-xs;
    }
  }
}

#login form {
  background: $cs-color-white;
  box-shadow: 0 3px 6px #00000029;
  margin: $cs-pad-lg * 2 auto;
  padding: $cs-pad-xl * 1.5 $cs-pad-xl * 2.5;
  width: 648px;
  border: {
    color: $cs-grey-3;
    radius: 8px;
    style: solid;
    width: 1px;
  }

  .btn {
    align-self: center;
    font-size: $cs-font-size-lg;
    height: 60px;
    justify-self: center;
    margin-top: $cs-pad-xl;
  }
}
