@use 'sass:color';

@import '../partials/variables';

$fade-duration: 200ms;

.dialog-container,
.dialog-container .dialog-bg {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.dialog-overlay .fade {
  transition: opacity $fade-duration;
  z-index: 0;

  // dark background overlay
  .dialog-bg {
    background: color.change($cs-grey-0, $alpha: 0);
    height: 100vh;
    transition: background $fade-duration;
    width: 100vw;
  }

  &.in {
    .dialog-bg {
      background: color.change($cs-grey-0, $alpha: 0.875);
    }
  }

  .dialog {
    opacity: 0;
  }

  &.in .dialog {
    opacity: 1;
  }
}

.dialog-container {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;

  // close icon
  button[title='Close'] {
    position: absolute;
    right: $cs-pad-sm;
    top: $cs-pad-sm;
  }

  .dialog {
    background: $cs-color-white;
    border: 1px solid $cs-grey-3;
    display: block;
    position: relative;
    transition: opacity $fade-duration;
  }

  .dialog-content,
  .dialog-title,
  .dialog-actions {
    margin: 0;
    padding: $cs-pad;
  }

  .dialog-title {
    border-bottom: 1px solid $cs-grey-3;
  }

  .dialog-actions {
    border-top: 1px solid $cs-grey-3;
  }

  .dialog-content p {
    margin: 0;
  }
}
