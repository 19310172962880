@use 'sass:math';
@use 'sass:color';

@import '../partials/variables';

/**
 * Styles for the sidebar menu.
 */

$menu-margin: $cs-pad-xs;
$menu-font-size: $cs-font-size * 2;
$menu-collapsed-width: $cs-pad-xl * 2.5;
$menu-expanded-width: $cs-pad-xl * 10;
$menu-button-height: $menu-collapsed-width;

$menu-overlay-out: color.change($cs-grey-0, $alpha: 0);
$menu-overlay-in: color.change($cs-grey-0, $alpha: 0.875);

#menu {
  background-color: color.change($cs-primary-3, $lightness: 90%);
  height: 100vh;
  overflow: hidden;
  position: fixed;
  transition: width $cs-animation-duration;
  width: $menu-collapsed-width;

  ul {
    margin: 0;
    padding: 0;
  }

  button i {
    opacity: 0.75;
  }

  a,
  button {
    align-items: center;
    color: $cs-color-body;
    display: flex;
    flex-direction: row;
    font-weight: $cs-weight-0;
    height: $menu-button-height;
    justify-content: flex-start;
    list-style: none;
    text-decoration: none;
    width: 100%;

    i {
      color: $cs-grey-3;
      flex: 0 0 $cs-pad-xl * 2.5;
      font-size: $menu-font-size;
      text-align: center;
    }

    .menu-text {
      font-size: $cs-font-size-lg;
      opacity: 0;
      transition: 0.2s opacity;
    }

    &.active {
      background-color: $cs-primary-3;
      border-left: math.div($menu-margin, 2) solid $cs-primary-0;
      i {
        $width: math.div($menu-margin, -2);

        color: $cs-grey-2;
        left: $width;
        margin-right: $width;
        position: relative;
      }
    }

    &:hover {
      opacity: 0.75;
    }
  }

  &.expanded {
    width: $menu-expanded-width;
    .menu-text {
      opacity: 1;
    }
  }
}

.menu-overlay-bg {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  transition: background $cs-animation-duration;
  width: 100vw;

  &.out {
    background: $menu-overlay-out;
  }

  &.in,
  &.expanded {
    background: $menu-overlay-in;
    cursor: pointer;
  }
}
